import React, { useState, useEffect } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Contact = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_m9ro5i4", 
        "template_hxy7msb",
        {
          to_name: formData.name,
          from_name: "School Contact Form",
          message: formData.message,
          email: formData.email,
          contact: formData.contact,
        },
        "vI-tIdVdnGjJmsfD5" 
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
        },
        (error) => {
          console.error("Error sending message:", error);
        }
      );
  };

  return (
    <>
      <Navbar />

      <div className="contact-cc-container">
        <div className="contact-cc-box contact-cc-box1">
          <h4>SCHOOL CONNECT</h4>
          <div className="contact-cc-inboxs">
            <div>
              <p>
                <h5>
                  School Relationship In-charge (for General information)
                </h5>
              </p>
              <p>Mobile No. - 7995001771</p>
              <p>
                <strong>School Fee Desk (for Fee and dues)</strong>
              </p>
              <p>Mobile No. - 9121283337</p>
            </div>
            <div>
              <p>
                <h5>School Transport Manager (School transport)</h5>
              </p>
              <p>Mobile No. - 9281019198</p>
              <p>
                <strong>School Official Mail ID</strong>
              </p>
              <p>info@gdgoenkahyderabad.com</p>
            </div>
          </div>
          <div className="contact-cc-inboxs">
            <div>
              <p>
                <h5>School HR mail ID</h5>
              </p>
              <p>hr@gdgoenkahyderabad.com</p>
              <p>
                <strong>School Principal’s Mail ID</strong>
              </p>
              <p>principal@gdgoenkahyderabad.com</p>
            </div>
            <div>
              <h5>SCHOOL OFFICE TIMINGS</h5>

              <p>9.00am to 4.00pm</p>

              <p>
                <strong>School Counselor</strong>
              </p>
              <p>11.00am to 12.30pm</p>
            </div>
          </div>
        </div>

        <div className="contact-cc-box contact-cc-box2">
          <form className="form-mainb" onSubmit={handleSubmit}>
            <div className="contact-cc-form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="contact-cc-form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="contact-cc-form-group">
              <label htmlFor="contact">Contact Number</label>
              <input
                type="tel"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                required
              />
            </div>

            <div className="contact-cc-form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button type="submit" className="contact-cc-submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>

      
    
    </>
  );
};

export default Contact;
