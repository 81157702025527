import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const AdministrationRules = () => (
  <>
  <Navbar />

  <div className="banner">
        <img src="./images/Administration Rules.jpg" />
      </div>
  
  <section className="section-mainbox">
    <h2>Administration Rules</h2>
    <p>At <em>GD Goenka</em>, we maintain a structured and supportive environment to ensure a safe and conducive atmosphere for learning. Our administration is committed to upholding the highest standards of discipline and respect. The key rules and guidelines include:</p>
    <ul>
        <li><strong>Punctuality:</strong> All students must adhere to the school timings. Regular attendance is essential for consistent academic progress and participation in school activities.</li>
        <li><strong>Dress Code:</strong> Students are required to wear the prescribed school uniform, maintaining a neat and tidy appearance at all times.</li>
        <li><strong>Respect and Conduct:</strong> Mutual respect is at the core of our school culture. Students are expected to treat their peers, teachers, and staff with respect and kindness, promoting a harmonious environment.</li>
        <li><strong>Active Participation:</strong> We encourage all students to participate actively in academic and extracurricular activities. This enhances their learning experience and builds confidence.</li>
        <li><strong>No Bullying Policy:</strong> The school has a zero-tolerance policy for bullying, harassment, or any form of discrimination. We foster an inclusive environment where every student feels safe and valued.</li>
        <li><strong>Parental Involvement:</strong> Parents are encouraged to engage in their child's educational journey by supporting school rules, attending parent-teacher meetings, and being proactive in their child’s development.</li>
    </ul>
    <p>By adhering to these guidelines, we ensure that every student can learn, grow, and succeed in a positive, supportive environment.</p>
</section>
  
  </>


);

export default AdministrationRules;
