import React, { useEffect } from 'react';
import './HolisticDevelopment.css';
import Navbar from '../components/Navbar';

const HolisticDevelopment = () => {

    useEffect(() => {
        const handleScroll = () => {
          const windowHeight = window.innerHeight;
          const scrollY = window.scrollY;
    
          const animatables = document.querySelectorAll('.animatable');
    
          animatables.forEach(animatable => {
            const elementTop = animatable.getBoundingClientRect().top + scrollY;
            const elementBottom = elementTop + animatable.clientHeight;
    
            // Check if element is in the viewport
            if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
              animatable.classList.add('animated');
              animatable.classList.remove('animatable');
            } else {
              // Reset the animation when the element is out of view
              animatable.classList.remove('animated');
              animatable.classList.add('animatable');
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Trigger animation on mount
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


  return (
    <>
    
    
    <div className="unique-container">
      <div className="unique-box unique-box1 block animatable fadeInUp">
        <p>
          <strong className='heading-h1'> Holistic Development</strong>
          <hr />
          <em>Holistic Development at GD Goenka</em>
          <br />
          <br />
          At GD Goenka, we believe in a holistic approach to education, encompassing:
          <br />
          <br />
          <strong className='sub-heading'>1. Academic Excellence:</strong>
          <br />
          - A dynamic curriculum that encourages critical thinking, creativity, and intellectual curiosity.
          <br />
          - Integration of modern teaching methods and the latest technology to inspire students.
          <br />
          <br />
          <strong className='sub-heading'>2. Physical Development:</strong>
          <br />
          - World-class sports facilities and diverse programs to promote fitness, discipline, and teamwork.
          <br />
          - Regular sports events, competitions, and professional coaching to nurture athletic talents.
          <br />
          <br />
          <strong className='sub-heading'>3. Emotional and Social Growth:</strong>
          <br />
          - Emphasis on emotional intelligence and social skills development through structured activities.
          <br />
          - Comprehensive counseling and support services for student well-being.
          <br />
          <br />
          <strong className='sub-heading'>4. Creative and Artistic Expression:</strong>
          <br />
          - Platforms for students to explore their artistic talents in music, dance, drama, and visual arts.
          <br />
          - Active participation in cultural events, competitions, and workshops to enhance creativity.
          <br />
          <br />
          <strong className='sub-heading'>5. Life Skills:</strong>
          <br />
          - Focused programs on critical thinking, problem-solving, leadership, and adaptability.
          <br />
          - Regular workshops and seminars on life skills, career guidance, and future readiness.
        </p>
      </div>
      <div className="unique-box unique-box2 block animatable fadeInDown">
        <p>
          <strong className='heading-h1'> Educational Concepts</strong>
          <hr />
          <em>Innovative Educational Concepts at GD Goenka</em>
          <br />
          <br />
          <strong className='sub-heading'>1. STEAM Education:</strong>
          <br />
          - A unique approach combining Science, Technology, Engineering, Arts, and Mathematics.
          <br />
          - Hands-on projects and real-world problem-solving to enhance learning experiences.
          <br />
          <br />
          <strong className='sub-heading'>2. Digital Literacy:</strong>
          <br />
          - Comprehensive integration of digital tools and platforms for an enhanced learning environment.
          <br />
          - Training in cutting-edge technologies like coding, AI, robotics, and digital media.
          <br />
          <br />
          <strong className='sub-heading'>3. Inclusive Education:</strong>
          <br />
          - A welcoming and inclusive environment for students of all backgrounds and abilities.
          <br />
          - Specialized programs and support for diverse learning needs.
          <br />
          <br />
          <strong className='sub-heading'>4. Global Perspective:</strong>
          <br />
          - Exchange programs, international partnerships, and global curriculum integration.
          <br />
          - Encouraging a multicultural outlook and awareness of global issues.
          <br />
          <br />
          <strong className='sub-heading'>5. Environmental Awareness:</strong>
          <br />
          - Education focused on sustainability, conservation, and eco-friendly practices.
          <br />
          - Active participation in projects and initiatives aimed at environmental responsibility.
        </p>
      </div>
    </div>
    
    </>
  );
};

export default HolisticDevelopment;
