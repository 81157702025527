import React, { useState, useEffect } from "react";
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const images = [
  "./images/gallery_1.jpg",
  "./images/gallery_2.jpg",
  "./images/gallery_3.jpg",
  "./images/gallery_4.jpg",
  "./images/gallery_5.jpg",
  "./images/gallery_6.jpg",
  "./images/gallery_7.jpg",
  "./images/gallery_8.jpg",
  "./images/gallery_9.jpg",
  "./images/gallery_10.jpg",
  // Add more image URLs as needed
];

const HealthcareBlog = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleLightboxClick = (e) => {
    // Close lightbox if the click is on the overlay, not on the image
    if (e.target.className === "lightbox") {
      closeLightbox();
    }
  };

  return (
  
    <>
    
    <Navbar />
    
    <div className="section-mainbox">
      <h1>Healthcare Blog and Services</h1>
      <p>
        The health and well-being of our students are of paramount importance at <strong>GD Goenka</strong>. We have partnered with <strong>Kaminene Hospital</strong> to provide comprehensive healthcare services on campus. Our dedicated <em>Healthcare Blog</em> offers valuable insights into student health, wellness, and safety.
      </p>
      <ul>
        <li><strong>On-Campus Medical Room:</strong> Our school is equipped with a fully functional medical room staffed by a qualified nurse to provide immediate care for minor injuries, illnesses, and medical emergencies. Regular health check-ups and vaccination drives are conducted to monitor and maintain the well-being of our students.</li>
        <li><strong>Health and Wellness Initiatives:</strong> We promote a healthy lifestyle through regular health awareness programs, workshops, and seminars. Topics include nutrition, mental health, hygiene, and fitness, ensuring students are educated about maintaining their well-being.</li>
        <li><strong>Counseling Services:</strong> We offer counseling support to help students manage stress, anxiety, and emotional challenges. Our counselors provide guidance and support to ensure that every child has access to the help they need.</li>
      </ul>
      <p>
        Our <em>Healthcare Blog</em> keeps parents and students informed about the latest updates on health practices, wellness tips, and information on the medical services available at the school. It serves as a valuable resource for staying connected with our health initiatives and fostering a healthy school community.
      </p>
    </div>

    <div className="gallery-grid ">
          {images.map((src, index) => (
            <div
              className="gallery-item block animatable moveUp"
              key={index}
              onClick={() => openLightbox(index)}>
              <img
                src={src}
                alt={`Gallery image ${index + 1}`}
                className="gallery-image"
              />
            </div>
          ))}
        </div>

        {isOpen && (
          <div className="lightbox" onClick={handleLightboxClick}>
            <span className="lightbox-close" onClick={closeLightbox}>
              &times;
            </span>
            <img
              src={images[photoIndex]}
              alt={`Gallery image ${photoIndex + 1}`}
              className="lightbox-image"
            />
            <button
              className="lightbox-button lightbox-prev"
              onClick={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }>
              Previous
            </button>
            <button
              className="lightbox-button lightbox-next"
              onClick={() => setPhotoIndex((photoIndex + 1) % images.length)}>
              Next
            </button>
          </div>
        )}

        <div
          style={{
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "30px",
          }}>
          <a
            style={{
              margin: "auto",
              textAlign: "center",
              justifyContent: "center",
            }}
            href="https://www.facebook.com/shiva.ganga.9237"
            className="contact-cc-button block animatable moveUp">
            More... <i className="icon-right-open"></i>
          </a>
        </div>
  
    </>
  );
};

export default HealthcareBlog;
