import React from "react";
import "./Nutrition.css";
import Navbar from "../../components/Navbar";

const BelowTenYears = () => {
  return (
    <>
    
    <Navbar />
    <div className="meal-plan-container">
      <h2 className="age-group-title">Nutrition For Below 10 Years</h2>

      <div className="meal-day">
        <h3 className="day-title">Monday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Whole grain pancakes with mixed berries - <em>Fiber-rich and flavorful.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Greek yogurt with honey - <em>High in protein and probiotics.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Vegetable pasta with a side of steamed broccoli - <em>Balanced and nutritious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Carrot sticks with hummus - <em>Healthy and crunchy.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Lentil soup with whole grain bread - <em>Hearty and filling.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Scrambled eggs with whole grain toast - <em>Protein-rich and satisfying.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Apple slices with cheese - <em>Crunchy and protein-rich.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chicken and vegetable stir-fry with brown rice - <em>Balanced and nutritious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with banana and spinach - <em>Nutrient-rich and delicious.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Turkey meatballs with spaghetti - <em>Hearty and flavorful.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Tuesday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Oatmeal with fresh fruit - <em>Nutritious and filling.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Almonds and dried fruit - <em>Energy-boosting snack.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Veggie wrap with hummus - <em>Fresh and flavorful.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Sliced cucumber with ranch - <em>Refreshing and crunchy.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Stuffed bell peppers - <em>Healthy and satisfying.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Breakfast burrito with eggs and cheese - <em>Protein-rich and filling.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Hard-boiled eggs - <em>High in protein.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Turkey sandwich with veggies - <em>Nutritious and delicious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Fruit and yogurt - <em>Sweet and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Grilled chicken with quinoa and vegetables - <em>Balanced and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Wednesday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Smoothie bowl with granola and berries - <em>Delicious and nutrient-packed.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Apple slices with peanut butter - <em>Sweet and satisfying.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Quinoa salad with chickpeas and veggies - <em>Healthy and filling.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Greek yogurt with fruit - <em>Protein-rich and refreshing.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Vegetable curry with brown rice - <em>Spicy and comforting.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Bacon and eggs - <em>High in protein and filling.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Cheese cubes and grapes - <em>Tasty and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chicken Caesar salad - <em>Lean protein and fresh greens.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with protein powder - <em>High in protein and tasty.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Pork chops with mashed potatoes - <em>Hearty and satisfying.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Thursday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Chia seed pudding with fruit - <em>Rich in fiber and omega-3s.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Mixed nuts - <em>Healthy fats and protein.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Vegetable sushi rolls - <em>Fresh and flavorful.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Fresh fruit - <em>Sweet and hydrating.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Spinach and cheese stuffed mushrooms - <em>Delicious and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Ham and egg muffins - <em>High in protein and satisfying.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Yogurt with honey - <em>Sweet and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Grilled chicken wrap - <em>Protein-rich and delicious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Fruit smoothie - <em>Refreshing and nutrient-packed.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Beef stew with vegetables - <em>Hearty and comforting.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Friday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Fruit salad with yogurt - <em>Refreshing and nutritious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Whole grain crackers with cheese - <em>Crunchy and satisfying.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Veggie burger with a side salad - <em>Delicious and balanced.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with berries and spinach - <em>Healthy and energizing.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Black bean tacos with fresh salsa - <em>Flavorful and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Breakfast sandwich with egg and turkey - <em>High in protein and delicious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Trail mix - <em>Energy-boosting and tasty.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Tuna salad with whole grain crackers - <em>Protein-rich and balanced.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with protein powder - <em>Nutrient-rich and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> BBQ chicken with roasted vegetables - <em>Hearty and flavorful.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Saturday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Avocado toast with tomatoes - <em>Healthy fats and fresh veggies.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Fruit smoothie - <em>Refreshing and nutrient-packed.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Grilled vegetable sandwich - <em>Delicious and nutritious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Rice cakes with almond butter - <em>Crunchy and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Vegetable stir-fry with tofu - <em>Protein-rich and balanced.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Chicken sausage with scrambled eggs - <em>Protein-rich and filling.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Greek yogurt with honey - <em>Sweet and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> BBQ chicken wrap - <em>Delicious and satisfying.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Cheese sticks - <em>High in protein and easy.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Turkey meatloaf with mashed potatoes - <em>Hearty and comforting.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Sunday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Smoothie bowl with granola - <em>Nutritious and delicious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Mixed fruit - <em>Sweet and refreshing.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Stuffed zucchini boats - <em>Healthy and filling.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Carrot and celery sticks with hummus - <em>Crunchy and nutritious.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Eggplant Parmesan - <em>Hearty and flavorful.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and aids sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Omelette with mushrooms and cheese - <em>Protein-rich and tasty.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Hard-boiled eggs - <em>Simple and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Grilled salmon with quinoa - <em>High in protein and omega-3s.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Yogurt with fruit - <em>Sweet and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Roast chicken with roasted vegetables - <em>Comforting and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

    </div>


    
    </>
  );
};

export default BelowTenYears;
