import React from "react";
import "./CommonStyles.css";
import Navbar from "../components/Navbar";

const EducationalConcepts = () => (
  <>
    <Navbar />

    <div className="banner">
      <img src="./images/EDUCON.jpg" />
    </div>

    <div className="section-mainbox">
      <h2 className="gd-title">GD Goenka Educational Concepts</h2>
      <ul className="gd-list">
        <div className="gd-list-item">
          <h3 className="gd-subtitle">Problem-Based Learning (PBL):</h3>
          <p>
            Problem-Based Learning is an educational approach where students
            learn by engaging with real-world problems. Instead of traditional
            instruction, students are presented with a challenge or problem and
            work collaboratively to find solutions. This method helps students
            develop critical thinking, problem-solving, and research skills as
            they explore the issue, identify relevant knowledge, and apply it
            practically. PBL encourages independent learning, teamwork, and
            hands-on experience, making learning more relevant and engaging.
          </p>
          <h3 className="gd-subtitle">Inquiry-Based Learning (IBL):</h3>
          <p>
            Inquiry-Based Learning is a student-centered approach that
            encourages curiosity and exploration. In this model, students ask
            questions, investigate, and discover answers through guided research
            and experimentation. Teachers act as facilitators, helping students
            build on their prior knowledge and guiding them through the process
            of inquiry. This method promotes critical thinking, deeper
            understanding, and active engagement, as students take ownership of
            their learning by exploring topics of interest and drawing
            conclusions through evidence-based research.
          </p>
        </div>

        <div>
          <h3 className="gd-subtitle">STEAM Education:</h3>
          <li>
            A unique approach combining Science, Technology, Engineering, Arts, and Mathematics.
          </li>

          <li>
            Hands-on projects and real-world problem-solving enhance learning experiences.
          </li>
        </div>
        <div>
          <h3 className="gd-subtitle">Digital Literacy:</h3>
          <li>
            Comprehensive integration of digital tools and platforms for an enhanced learning environment.
          </li>

          <li>
            Training in cutting-edge technologies like coding, AI, robotics, and digital media.
          </li>
        </div>
        <div>
          <h3 className="gd-subtitle">Inclusive Education:</h3>
          <li>
            A welcoming and inclusive environment for students of all backgrounds and abilities.
          </li>

          <li>
           Specialized programs and support for diverse learning needs.
          </li>
        </div>
        <div>
          <h3 className="gd-subtitle">Global Perspective:</h3>
          <li>
            Exchange programs, international partnerships, and global curriculum integration.
          </li>

          <li>
            Encouraging a multicultural outlook and awareness of global issues.
          </li>
        </div>
        <div>
          <h3 className="gd-subtitle">Environmental Awareness:</h3>
          <li>
            Education focused on sustainability, conservation, and eco-friendly practices.
          </li>

          <li>
            Active participation in projects and initiatives aimed at environmental responsibility.
          </li>
        </div>
      </ul>
    </div>
  </>
);

export default EducationalConcepts;
