import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const images = [
  "./images/gallery_1.jpg",
  "./images/gallery_2.jpg",
  "./images/gallery_3.jpg",
  "./images/gallery_4.jpg",
  "./images/gallery_5.jpg",
  "./images/gallery_6.jpg",
  "./images/gallery_7.jpg",
  "./images/gallery_8.jpg",
  "./images/gallery_9.jpg",
  "./images/gallery_10.jpg",
  "./images/gallery_11.jpg",
  "./images/gallery_12.jpg",
  "./images/gallery_13.jpg",
  "./images/gallery_14.jpg",
  "./images/gallery_15.jpg",
  "./images/gallery_16.jpg",
  "./images/gallery_17.jpg",
  "./images/gallery_18.jpg",
  "./images/healthcare-01.jpg",
  "./images/healthcare-02.jpg",
  "./images/healthcare-03.jpg",
  "./images/healthcare-04.jpg",
  "./images/healthcare-05.jpg",
  "./images/healthcare-06.jpg",
];

const videoIds = [
  "qTJEKPpleYw?si=ZoyVlcmOhOiifxMR", // Replace with actual video IDs
  "hAY2LJOCIcI?si=30ir292MW-5Q6-xn",
  "VtjCoTlqCtg?si=GpRsqrT2yYryCsTN",
];

const Gallery = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleLightboxClick = (e) => {
    // Close lightbox if the click is on the overlay, not on the image
    if (e.target.className === "lightbox") {
      closeLightbox();
    }
  };

  return (
    <>
      <Navbar />

      <h1 className="box-title2">GALLERY</h1>

      <div className="gallery-container">
        <div className="gallery-grid ">
          {images.map((src, index) => (
            <div
              className="gallery-item block animatable moveUp"
              key={index}
              onClick={() => openLightbox(index)}>
              <img
                src={src}
                alt={`Gallery image ${index + 1}`}
                className="gallery-image"
              />
            </div>
          ))}
        </div>

        {isOpen && (
          <div className="lightbox" onClick={handleLightboxClick}>
            <span className="lightbox-close" onClick={closeLightbox}>
              &times;
            </span>
            <img
              src={images[photoIndex]}
              alt={`Gallery image ${photoIndex + 1}`}
              className="lightbox-image"
            />
            <button
              className="lightbox-button lightbox-prev"
              onClick={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }>
              Previous
            </button>
            <button
              className="lightbox-button lightbox-next"
              onClick={() => setPhotoIndex((photoIndex + 1) % images.length)}>
              Next
            </button>
          </div>
        )}

        <div
          style={{
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "30px",
          }}>
          <a
            style={{
              margin: "auto",
              textAlign: "center",
              justifyContent: "center",
            }}
            href="https://www.facebook.com/shiva.ganga.9237"
            className="contact-cc-button block animatable moveUp">
            More... <i className="icon-right-open"></i>
          </a>
        </div>

        <div className="video-section">
          <h1 className="box-title">Watch Our Videos</h1>
          <div className="video-grid ">
            {videoIds.map((id) => (
              <div className="video-item block animatable moveUp" key={id}>
                <iframe
                  title={id}
                  width="100%"
                  height="auto"
                  src={`https://www.youtube.com/embed/${id}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
