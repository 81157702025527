

export default [
    {
      title: "Transform Ideas into Reality: AI Education for Kids",
      description: "Empower young minds with the skills to innovate and create using AI. Join our engaging courses and watch your child's imagination come to life through technology.",
      urls: './images/home-slide-bg-11.jpg',
    },
    {
      title: "Your GateWay To Virtual Learning Excellence",
      description: "Learnorama.in brings education at your fingertips with a variety of enriching online classes. From academic subjects to practical skills, our virtual courses provide a dynamic and accessible learning experience. Join us online to discover a world of knowledge and elevate your learning journey from the convenience of your own device.",
      urls: './images/home-slide-bg-2.jpg',
    },
    
  ];
  