import React, { useEffect } from 'react';
import './FactSection.css'

const FactSection = () => {

    useEffect(() => {
        const handleScroll = () => {
          const windowHeight = window.innerHeight;
          const scrollY = window.scrollY;
    
          const animatables = document.querySelectorAll('.animatable');
    
          animatables.forEach(animatable => {
            const elementTop = animatable.getBoundingClientRect().top + scrollY;
            const elementBottom = elementTop + animatable.clientHeight;
    
            // Check if element is in the viewport
            if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
              animatable.classList.add('animated');
              animatable.classList.remove('animatable');
            } else {
              // Reset the animation when the element is out of view
              animatable.classList.remove('animated');
              animatable.classList.add('animatable');
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Trigger animation on mount
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


  return (
    <>
    
    <div style={{ marginBottom: "100px" }} className="fact-container">
      <div className="fact-row">
        <div className="fact-item wow fadeIn" data-wow-delay=".1s">
          <div className="d-flex counter block animatable bounceInLeft">
            <h1 className="me-3 text-primary counter-value">1</h1>
            <h5 className="text-white mt-1">
            Nurturing Tomorrow's Innovators with Global Standards.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".3s">
          <div className="d-flex counter block animatable bounceInLeft">
            <h1 className="me-3 text-primary counter-value">2</h1>
            <h5 className="text-white mt-1">
            Pioneering Education in Hyderabad with AI and Robotics Labs.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".5s">
          <div className="d-flex counter block animatable bounceInLeft">
            <h1 className="me-3 text-primary counter-value">3</h1>
            <h5 className="text-white mt-1">
            Where Excellence Meets Innovation and Holistic Learning.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".7s">
          <div className="d-flex counter block animatable bounceInLeft">
            <h1 className="me-3 text-primary counter-value">4</h1>
            <h5 className="text-white mt-1">
            Empowering Young Minds with World-Class Facilities.
            </h5>
          </div>
        </div>
      </div>
      <div className="fact-row">
        <div className="fact-item wow fadeIn" data-wow-delay=".1s">
          <div className="d-flex counter block animatable bounceInRight">
            <h1 className="me-3 text-primary counter-value">5</h1>
            <h5 className="text-white mt-1 ">
            Building Future Leaders through an Integrated Curriculum.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".3s">
          <div className="d-flex counter block animatable bounceInRight">
            <h1 className="me-3 text-primary counter-value">6</h1>
            <h5 className="text-white mt-1">
            Experience a New Era of Learning with International Standards.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".5s">
          <div className="d-flex counter block animatable bounceInRight">
            <h1 className="me-3 text-primary counter-value">7</h1>
            <h5 className="text-white mt-1">
            Holistic Education for a Brighter Tomorrow.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".7s">
          <div className="d-flex counter block animatable bounceInRight">
            <h1 className="me-3 text-primary counter-value">8</h1>
            <h5 className="text-white mt-1">
            Your Child's Future Starts Here with Global Excellence.
            </h5>
          </div>
        </div>
      </div>
    </div>
    
    
    </>
    
  );
};

export default FactSection;
