import React from "react";
import Navbar from "../components/Navbar";

const GdGoenkaClubs = () => {

  const clubs = [
    { name: "Helix Weaver Club", imgSrc: "./images/Helix.jpg" },
    { name: "AI Club", imgSrc: "./images/AI-Club.jpg" },
    { name: "Jurno Junction Club", imgSrc: "./images/Jurno-Junction-Club.png" },
    { name: "Creation Cart Club", imgSrc: "./images/Creation-Cart.jpg" },
    { name: "Planet Love Club", imgSrc: "./images/Planet-club.jpg" },
    { name: "Litflix Club", imgSrc: "./images/Litflix-Club.jpg" },
    { name: "My League Club", imgSrc: "./images/my-league-Club-table-tennis.jpg" },
    { name: "Satya Shala Club", imgSrc: "./images/Satya-Shala-Club.jpg" },
    { name: "Limelight Club", imgSrc: "./images/Limelight-Club.jpg" },
    { name: "7 Notes Club", imgSrc: "./images/Notes-Club.jpg" },
    { name: "Sci-fi Club", imgSrc: "./images/sci-club.jpg" },
    { name: "Rhythm Club", imgSrc: "./images/Rhythm-Club.jpg" },
    { name: "Pi-rates Club", imgSrc: "./images/Pi-rates-Club.jpg" },
    { name: "Nritya Club", imgSrc: "./images/Nritya-Club.jpg" },
    { name: "Photography Club", imgSrc: "./images/Photography-Club.jpg" },
    { name: "Karate Club", imgSrc: "./images/Karate-Club.jpg" },
    { name: "Sargam Club", imgSrc: "./images/Sargam-Club.jpg" },
    { name: "Skating Club", imgSrc: "./images/Skating-Club.jpg" },
    // { name: "Satyam Club", imgSrc: "./images/Satya-Shala-Club.jpg" },
  ];

  return (
    <>
      <Navbar />

      <h1 className="box-title2">Gd Goenka Clubs</h1>

      

      <div className="clubs-container">
      {clubs.map((club, index) => (
        <div className="club-card" key={index}>
          <img src={club.imgSrc} alt={club.name} className="club-image" />
          <h1 className="club-name">{club.name}</h1>
        </div>
      ))}
    </div>
    </>
  );
};

export default GdGoenkaClubs;
