// import React, { useEffect } from "react";
// import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
// import { Link } from "react-router-dom";
// import "./More.css";

// const More = () => {
//   useEffect(() => {
//     const handleScroll = () => {
//       const windowHeight = window.innerHeight;
//       const scrollY = window.scrollY;

//       const animatables = document.querySelectorAll(".animatable");

//       animatables.forEach((animatable) => {
//         const elementTop = animatable.getBoundingClientRect().top + scrollY;
//         const elementBottom = elementTop + animatable.clientHeight;

//         // Check if element is in the viewport
//         if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
//           animatable.classList.add("animated");
//           animatable.classList.remove("animatable");
//         } else {
//           // Reset the animation when the element is out of view
//           animatable.classList.remove("animated");
//           animatable.classList.add("animatable");
//         }
//       });
//     };

//     window.addEventListener("scroll", handleScroll);
//     handleScroll(); // Trigger animation on mount

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <>
//       <Navbar />

//       <div className="more-container">
//         <h2 className="heading">Short Links</h2>

//         <div className="links-container">
//           <Link
//             className="link-box block animatable fadeInDown"
//             to="/schoolmanagment">
//             <p>School Managment</p>
//           </Link>

//           <Link className="link-box block animatable fadeInDown" to="/newsblog">
//             <p>Latest Blog</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInUp"
//             to="/educationalconcepts">
//             <p>Educational Concepts</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInDown"
//             to="/sportsmanagement">
//             <p>Sports Management</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInUp"
//             to="/administrationrules">
//             <p>Administration Rules</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInDown"
//             to="/advancedsciencelabs">
//             <p>Advanced Science Labs</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInUp"
//             to="/nutritionforkids">
//             <p>Healthcare Blog</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInDown"
//             to="/educationalconcepts">
//             <p>Educational Concepts</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInUp"
//             to="/sportsmanagement">
//             <p>SportsManagement</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInDown"
//             to="/administrationrules">
//             <p>Administration Rules</p>
//           </Link>

//           <Link
//             className="link-box block animatable fadeInUp"
//             to="/advancedsciencelabs">
//             <p>Advanced Science Labs</p>
//           </Link>
//         </div>
//       </div>
//     </>
//   );
// };

// export default More;




import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import "./More.css";

const More = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />

      <div className="more-boxes-container">
        <Link to="/schoolmanagment" className="more-box animatable block animatable bounceInLeft">
          
          <h1 className="more-box-title">School Management</h1>
        </Link>
        <Link to="/infrastructure" className="more-box animatable block animatable bounceInRight">
          
          <h1 className="more-box-title">Infrastructure</h1>
        </Link>

        <Link to="/gdgoenkahouses" className="more-box animatable block animatable bounceInLeft">
          
          <h1 className="more-box-title">Gd Goenka Houses</h1>
        </Link>
        <Link to="/curriculum" className="more-box animatable block animatable bounceInRight">
          
          <h1 className="more-box-title">Curriculum</h1>
        </Link>
        
        
        <Link to="/educationalconcepts" className="more-box animatable block animatable bounceInLeft">
          
          <h1 className="more-box-title">Educational Concepts</h1>
        </Link>

        {/* <Link to="/enquireform" className="more-box animatable block animatable bounceInLeft">
          <img src="/images/more-08.jpg" alt="Enquire Form" className="more-box-image" />
          <h1 className="more-box-title">Enquire Form</h1>
        </Link> */}
        
        <Link to="/administrationrules" className="more-box animatable block animatable bounceInLeft">
          
          <h1 className="more-box-title">Administration Rules</h1>
        </Link>

        <Link to="/gdgoenkaclubs" className="more-box animatable block animatable bounceInLeft">
          <h1 className="more-box-title">Gd Goenka Clubs</h1>
        </Link>
      </div>

      
    </>
  );
};

export default More;
