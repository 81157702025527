import React from 'react';
import { Routes, Route } from 'react-router-dom'; // Import necessary components
import Home from './components/Home';
import About from './Pages/About';
import Footer from './components/Footer';
import Contact from './Pages/Contact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import More from './Pages/More';
import EducationalConcepts from './Pages/EducationalConcepts';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions.js';
import RefundPolicy from './Pages/RefundPolicy.js';
import SportsManagement from './Pages/SportsManagement.js';
import AdministrationRules from './Pages/AdministrationRules.js';
import ComputerLab from './Pages/ComputerLab.js';
import NewsBlog from './Pages/NewsBlog.js';
import HealthcareBlog from './Pages/HealthcareBlog.js';
import NutritionForKids from './Pages/Nutrition For Kids/NutritionForKids.js';
import BelowSixYears from './Pages/Nutrition For Kids/BelowSixYears.js';
import BelowTenYears from './Pages/Nutrition For Kids/BelowTenYears.js';
import BelowFifteenYears from './Pages/Nutrition For Kids/BelowFifteenYears.js';
import FAQ from './Pages/FAQ.js';
import ScrollToTop from './components/ScrollToTop.js';
import SchoolManagment from './Pages/SchoolManagment.js';
import Infrastructure from './Pages/Infrastructure.js';
import Curriculum from './Pages/Curriculum.js';
import Gallery from './Pages/Gallery.js';
import GdGoenkaHouses from './Pages/GdGoenkaHouses.js';
import GdGoenkaClubs from './Pages/GdGoenkaClubs.js';

function App() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/more" element={<More />} />
        <Route path="/educationalconcepts" element={<EducationalConcepts />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/sportsmanagement" element={<SportsManagement />} />
        <Route path="/administrationrules" element={<AdministrationRules />} />
        
        <Route path="/computerlab" element={<ComputerLab />} />
        <Route path="/newsblog" element={<NewsBlog />} />
        <Route path="/healthcareblog" element={<HealthcareBlog />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/nutritionforkids" element={<NutritionForKids />} />
        <Route path="/belowsixyears" element={<BelowSixYears />} />
        <Route path="/belowtenyears" element={<BelowTenYears />} />
        <Route path="/belowfifteenyears" element={<BelowFifteenYears />} />


        <Route path="/schoolmanagment" element={<SchoolManagment />} />
        <Route path="/infrastructure" element={<Infrastructure />} />

        <Route path="/curriculum" element={<Curriculum />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gdgoenkahouses" element={<GdGoenkaHouses />} />
        <Route path="/gdgoenkaclubs" element={<GdGoenkaClubs />} />
        {/* <Route path="/belowtenyears" element={<BelowTenYears />} /> */}
        {/* <Route path="/belowfifteenyears" element={<BelowFifteenYears />} /> */}
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
