import React, { useEffect } from 'react';

function SliderContent({ activeIndex, sliderImage }) {

    useEffect(() => {
        const handleScroll = () => {
          const windowHeight = window.innerHeight;
          const scrollY = window.scrollY;
    
          const animatables = document.querySelectorAll('.animatable');
    
          animatables.forEach(animatable => {
            const elementTop = animatable.getBoundingClientRect().top + scrollY;
            const elementBottom = elementTop + animatable.clientHeight;
    
            // Check if element is in the viewport
            if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
              animatable.classList.add('animated');
              animatable.classList.remove('animatable');
            } else {
              // Reset the animation when the element is out of view
              animatable.classList.remove('animated');
              animatable.classList.add('animatable');
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Trigger animation on mount
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img className="slide-image block animatable bounceInLeft" src={slide.urls} alt="" />
          {/* <h2 className="slide-title block animatable bounceInRight">{slide.title}</h2> */}
          {/* <h3 className="slide-text block animatable bounceInLeft">{slide.description}</h3> */}
        
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
