import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
// import Footer from "../components/Footer";

const About = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />

      <div className="about-bb-section">
        <div className="about-bb-section_wrapper">
          <div className="about-bb-wrap about-bb-two-fifth block animatable bounceInLeft">
            <div className="about-bb-wrap-inner">
              <div className="about-bb-column about-bb-column_column">
                <div className="about-bb-column_attr">
                  <h1 className="about-bb-heading-main">ABOUT</h1>
                  <h1 className="about-bb-heading-sub">
                    Why GD Goenka School, Hyderabad?
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="about-bb-wrap about-bb-three-fifth block animatable bounceInRight">
            <div className="about-bb-wrap-inner">
              <div className="about-bb-column about-bb-column_image">
                <div className="about-bb-image_frame">
                  <div className="about-bb-image_wrapper">
                    <img
                      className="about-bb-image"
                      src="./images/gallery_4.png"
                      alt="barman2-subheader"
                      width="780"
                      height="581"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="why-goenka-container">
        <div className="wrapper">
          <section className="section-1 block animatable bounceInLeft">
            <h3>Why GD Goenka School, Hyderabad?</h3>
            <p>
              At G. D Goenka School, Hyderabad, one of the upcoming best CBSE
              schools, we endeavor to promote a classroom of thinkers who would
              have the courage and conviction to lead the world with their
              astute insight, competency, caliber, and impeccable values. The
              school’s motto ‘Together - Towards - Tomorrow Seamlessly’
              epitomizes the school’s commitment to broaden the students’
              horizons and enable them to carve an integrated and an enlightened
              world - a world that fosters unity yet celebrates the uniqueness
              of each individual.
            </p>
          </section>
        </div>
        <div className="wrapper">
          <section className="section-2 block animatable bounceInRight">
            <h3>VISION</h3>
            <p>
              To provide every child with the best opportunities to grow and
              thrive each day, while uncovering and nurturing their individual
              talents and strengths in meaningful ways.
            </p>
          </section>
        </div>
        <div className="wrapper">
          <section className="section-3 block animatable bounceInLeft">
            <h3>MISSION</h3>
            <p>
              We will create an environment in which all children and adults
              feel welcomed, respected, trusted and an important part of the
              school. We will create an environment where each child will be
              valued for their individually and diverse capabilities as they
              will be taught to face the future and contribute to society with
              compassion courage Knowledge and Vision. We will create a
              child-centric, brain-compatible environment to provide holistic
              development which will create visionaries in any ever-changing
              world.
            </p>
          </section>
        </div>
      </div>

      <div className="more-boxes-container">
        <Link
          to="/schoolmanagment"
          className="more-box animatable block animatable bounceInLeft">
          <h1 className="more-box-title">School Management</h1>
        </Link>
        <Link
          to="/infrastructure"
          className="more-box animatable block animatable bounceInRight">
          <h1 className="more-box-title">Infrastructure</h1>
        </Link>

        <Link
          to="/curriculum"
          className="more-box animatable block animatable bounceInRight">
          <h1 className="more-box-title">Curriculum</h1>
        </Link>

        <Link
          to="/educationalconcepts"
          className="more-box animatable block animatable bounceInLeft">
          <h1 className="more-box-title">Educational Concepts</h1>
        </Link>

        {/* <Link to="/enquireform" className="more-box animatable block animatable bounceInLeft">
          <img src="/images/more-08.jpg" alt="Enquire Form" className="more-box-image" />
          <h1 className="more-box-title">Enquire Form</h1>
        </Link> */}

        <Link
          to="/administrationrules"
          className="more-box animatable block animatable bounceInLeft">
          <h1 className="more-box-title">Administration Rules</h1>
        </Link>

        <Link
          to="/gdgoenkahouses"
          className="more-box animatable block animatable bounceInLeft">
          <h1 className="more-box-title">GdGoenkaHouses</h1>
        </Link>

        <Link
          to="/gdgoenkaclubs"
          className="more-box animatable block animatable bounceInLeft">
          <h1 className="more-box-title">Gd Goenka Clubs</h1>
        </Link>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default About;
