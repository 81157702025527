import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const PrivacyPolicy = () => (
    <>
    
    <Navbar />
    
    <div className="section-mainbox">
    <h2 className="gd-title">Privacy Policy</h2>
    <p>At GD Goenka, Hyderabad, your privacy is paramount. We collect and use personal data to improve our educational services and ensure a personalized experience for each student and parent. Information collected includes contact details, academic records, and health information, which are safeguarded through stringent security measures. We ensure that your data is never shared with third parties without your explicit consent, adhering to strict confidentiality standards.</p>
      <p>You have the right to access, correct, or delete your personal information at any time. We are committed to transparency and will inform you of any changes to our privacy practices. Our administration is always available to address any concerns or questions you may have regarding your privacy. Protecting your information is a responsibility we take very seriously, and we strive to maintain your trust.</p>
  </div>
  
  </>
);

export default PrivacyPolicy;
