import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const RefundPolicy = () => (
   <>
   
   
   <Navbar />
    
    <div className="section-mainbox">
    <h2 className="gd-title">Refund Policy</h2>
    <p>GD Goenka, Hyderabad is dedicated to ensuring your satisfaction with our educational services. If you are not completely satisfied, you may request a refund within 30 days of payment. Refund requests are carefully reviewed and processed based on the specific circumstances, ensuring a fair resolution for all parties involved. We aim to handle each request promptly and transparently.</p>
      <p>Our administration team is here to assist you with any questions or concerns regarding the refund process. We strive to provide high-quality education and services, and your feedback is valuable in helping us improve. For more detailed information on how to request a refund, please contact our administration office, where our team will guide you through the necessary steps.</p>
  </div>
  
  </>


);

export default RefundPolicy;
