import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const ComputerLab = () => (
    <>
    
    <Navbar />

    <div className="banner">
        <img src="./images/Advanced-computer-labs.jpg" />
      </div>
    
    <section className="section-mainbox">
    <h2>Computer Lab</h2>
    <p>At <em>GD Goenka</em>, we understand the importance of digital literacy in today’s technology-driven world. Our <strong>Computer Lab</strong> is a hub of innovation, designed to foster creativity, critical thinking, and technical skills among students.</p>
    <ul>
        <li><strong>State-of-the-Art Facilities:</strong> The lab is equipped with the latest computers, high-speed internet, and advanced software to support a wide range of learning activities, from basic computer skills to advanced coding and programming.</li>
        <li><strong>Comprehensive Curriculum:</strong> We offer a structured curriculum that includes training in digital literacy, computer science, coding, robotics, and cybersecurity. Our programs are designed to help students understand and master the latest technologies.</li>
        <li><strong>Hands-On Learning:</strong> Students engage in hands-on projects and activities that encourage them to apply their knowledge in practical situations, such as developing websites, creating apps, and programming robots.</li>
        <li><strong>Expert Guidance:</strong> Under the supervision of experienced instructors, students learn essential tech skills and gain exposure to emerging fields such as artificial intelligence, machine learning, and data science.</li>
        <li><strong>Innovation and Creativity:</strong> Our computer lab fosters a culture of innovation and creativity, providing students with opportunities to participate in coding competitions, hackathons, and tech expos.</li>
    </ul>
    <p>By integrating digital literacy into the curriculum, GD Goenka, prepares students to be tech-savvy, future-ready leaders in a rapidly evolving digital landscape.</p>
</section>
  
  </>


);

export default ComputerLab;
