import React, { useState } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";

import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "#000",
    fontSize: "20px",
    fontFamily: 'Baskervville SC',
  },
  icon: {
    color: "#000",
    width: "10px",
  },
  profileImg: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    // marginLeft: theme.spacing(2),
    margin: "auto",
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  // Replace with your actual profile image URL
  const profileImgUrl = "/images/homepg-22.jpg";

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        className="navbar">
        <List>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/" className={classes.link}>
                HOME
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/about" className={classes.link}>
                ABOUT US
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/contact" className={classes.link}>
                CONTACT US
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/gallery" className={classes.link}>
              GALLERY
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/infrastructure" className={classes.link}>
              INFRASTRUCTURE
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/Curriculum" className={classes.link}>
               CURRICULUM
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/schoolmanagment" className={classes.link}>
              SCHOOL MANAGEMENT
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
    
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/educationalconcepts" className={classes.link}>
              EDUCATIONAL CONCEPTS
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/administrationrules" className={classes.link}>
              ADMINISTRATION RULES
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/gdgoenkahouses" className={classes.link}>
              GD GOENKA HOUSES
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/gdgoenkaclubs" className={classes.link}>
              GD GOENKA CLUBS
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/newsblog" className={classes.link}>
              NEWS BLOGS
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/sportsmanagement" className={classes.link}>
              SPORTS MANAGEMENT
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        className={classes.icon}>
        <MenuIcon />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
