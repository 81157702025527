import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const SchoolManagment = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    
    <Navbar />
    
    <div className="school-management-container">
      <h1 className='box-title2'>School Management</h1>

      <section className="management-profile">
        <img src='./images/Chairman.jpg' alt="Mr. Thota Shiva Yadav, Chairman" className="profile-image" />
        <div className="profile-info">
          <h2>Mr. Thota Shiva Yadav</h2>
          <h3>Chairman</h3>
        </div>
      </section>

      <h2 className="title">Mind When Stretched by a New Idea Never Regains its Original Dimensions!</h2>
      <p className="paragraph">
        GD Goenka School, Hyderabad, provides a highly conducive setting to transmit to the students a more tangible dimension of the lessons and aims to create holistic, humanistic and conducive learning environment which creates global citizens who are compassionate, environmentally responsible, life-long learners who can become the best versions of themselves. With the arrival of new information and communication technologies to schools, both new teaching methodologies as well as new versions of existing methodologies, now revised and updated for the digital generation, have emerged that are driving better academic performance among students. It is clear we have entered a new era and as we look to the future, at GDGS, we explore changes in our academic attentions and in our curriculum to meet the need of the hour. At GDGS, Hyderabad, we understand that opportunities to elicit change and spur creativity are endless and team GDGS endeavors to bring forth the true value of learning, inspiring learners, and making education more relevant and empowering with a mission to establish a school for the leaders of tomorrow.
      </p>
      <p className="paragraph">
        I wish the Principal and Team GDGS all the very best in their endeavors!
      </p>

      <section className="management-profile">
        <img src='./images/Director.jpg' alt="Mrs. Nivedita Shiva Kumar, Director" className="profile-image" />
        <div className="profile-info">
          <h2>Mrs. Nivedita Shiva Kumar</h2>
          <h3>Director</h3>
        </div>
      </section>

      <section>
        <h2>Director’s Message</h2>
        <p>
          Welcome to GD Goenka School, where we are committed to providing a nurturing environment that fosters educational excellence and all-round development. Our dedicated staff works tirelessly to ensure that each student reaches their full potential.
        </p>
        <p>
          At GD Goenka School, we believe in creating a learning experience that goes beyond textbooks and classrooms. Our curriculum is designed to develop critical thinking, creativity, and a love for learning in each student.
        </p>
        <p>
          We are proud of our state-of-the-art facilities, experienced faculty, and the vibrant community of learners. Together, we strive to make GD Goenka School a place where every child can grow, learn, and thrive.
        </p>
        <p>
          Join us in our journey towards educational excellence. We look forward to welcoming you to our school community.
        </p>
      </section>

      <section>
        <h2>Our Vision</h2>
        <p>
        To provide every child with the best opportunities to grow and thrive each day, while uncovering and nurturing their individual talents and strengths in meaningful ways.
        </p>
      </section>

      <section>
        <h2>Our Mission</h2>
        <p>
          Our mission is to provide high-quality education that prepares students for the challenges of the future. We are committed to fostering academic excellence, personal growth, and social responsibility in each student.
        </p>
        <ul>
          <li>To provide a safe and supportive learning environment.</li>
          <li>To promote critical thinking and problem-solving skills.</li>
          <li>To encourage creativity and innovation.</li>
          <li>To instill values of respect, responsibility, and integrity.</li>
          <li>To prepare students for lifelong learning and global citizenship.</li>
        </ul>
      </section>
      
      <section>
        <h2>Our Values</h2>
        <p>We believe in the core values of:</p>
        <ul>
          <li>Integrity: Upholding honesty and strong moral principles.</li>
          <li>Excellence: Striving for the highest standards in everything we do.</li>
          <li>Respect: Valuing diversity and treating everyone with dignity.</li>
          <li>Innovation: Encouraging creativity and embracing new ideas.</li>
          <li>Collaboration: Working together to achieve common goals.</li>
        </ul>
      </section>
    </div>

   
    
    </>
  )
}

export default SchoolManagment;