import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const Infrastructure = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />

      <h1 className="box-title2">INFRASTRUCTURE</h1>

      <div className="boxes-container">
        <div className="box box1">
          <img
            src="./images/school-building-01.jpeg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
        <div className="box box2">
          <h2 className="box-title">World-Class Campus with International Facilities</h2>
          <p className="box-text">
            GD Goenka School, Hyderabad, offers a range of excellent facilities
            and a highly conducive setting to transmit to the students a more
            tangible dimension of the lessons. Located in Rock Town Colony,
            Hyderabad, set in a serene and pollution free environment, the
            unique and ultra-modern architectural aesthetics of the school
            building give it a distinct identity and set the tone for positivity
            and a perfect balance of energies.
          </p>
        </div>
      </div>
      <hr className="hr-01" />

      <div className="boxes-container">
        <div className="box box2">
          <h2 className="box-title">Innovative Classrooms Designed for Future Leaders</h2>
          <p className="box-text">
            The school features modern digital classrooms designed aesthetically
            to make both the learning and teaching interactive. With
            technological support, our skilled teachers provide guidance to each
            individual.
          </p>
        </div>

        <div className="box box1">
          <img
            src="./images/Infrastructure-02.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
      </div>
      <hr className="hr-01" />

      <div className="boxes-container">
        <div className="box box1">
          <img
            src="./images/Infrastructure-03.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
        <div className="box box2">
          <h2 className="box-title">Global Standard Library for Unlimited Learning</h2>
          <p className="box-text">
            An essential tool for establishing desirable reading and study
            habits among students, the school has a well-stocked library along
            with a digital library section which helps engage readers by
            providing access to the latest publications and other content
            resources.
          </p>
        </div>
      </div>
      <hr className="hr-01" />

      <div className="boxes-container">
        <div className="box box2">
          <h2 className="box-title">Advanced Science and Technology Laboratories</h2>
          <p className="box-text">
            The school has a well-equipped Science lab to The well-equipped
            Computer lab serves as the centre for teaching computer use to the
            students by qualified computer teachers. These labs are also used by
            Goenkans for research, for creating technology-based projects and
            multimedia presentations.
          </p>
        </div>

        <div className="box box1">
          <img
            src="./images/Infrastructure-04.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
      </div>
      <hr className="hr-01" />

      <div className="boxes-container">
        <div className="box box1">
          <img
            src="./images/Infrastructure-05.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
        <div className="box box2">
          <h2 className="box-title">Creative Activity Rooms for Holistic Development</h2>
          <p className="box-text">
            Additionally, the school houses well-designed art studios, music
            rooms, and dance studios to nurture the creative talents of our
            students.
          </p>
        </div>
      </div>
      <hr className="hr-01" />

      <div className="boxes-container">
        <div className="box box2">
          <h2 className="box-title">State-of-the-Art Sports Facilities for Every Athlete</h2>
          <p className="box-text">
            Sports facilities encompass a multipurpose playground for cricket
            and football, tennis courts, basketball courts, skating rink,
            badminton courts, volleyball court, indoor sports facilities and
            spacious recreational areas.
          </p>
        </div>

        <div className="box box1">
          <img
            src="./images/Infrastructure-06.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
      </div>
      <hr className="hr-01" />

      <div className="boxes-container">
        <div className="box box1">
          <img
            src="./images/Infrastructure-07.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
        <div className="box box2">
          <h2 className="box-title">24/7 Advanced CCTV Surveillance for a Safe Learning Environment</h2>
          <p className="box-text">
            Safety and security are paramount, ensuring a protected campus with
            CCTV surveillance and trained security staff.
          </p>
        </div>
      </div>
      <hr className="hr-01" />

      <div className="boxes-container">
        <div className="box box2">
          <h2 className="box-title">Safe and Reliable International School Transport</h2>
          <p className="box-text">
            The School makes transport arrangements for the children through its
            own fleet of air-conditioned buses equipped with tracking system
            facility, on an annual contract explicitly on request from parents.
            The bus facility is provided from the closest pick up and drop
            point. Door to door facility is not available and the school is at a
            liberty to change routing of buses as per requirements.
          </p>
        </div>

        <div className="box box1">
          <img
            src="./images/Infrastructure-08.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
      </div>
      <hr className="hr-01" />

      <div className="boxes-container">
        <div className="box box1">
          <img
            src="./images/Infrastructure-09.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
        <div className="box box2">
          <h2 className="box-title">SCHOOL DINING</h2>
          <p className="box-text">
            Meal time is an opportunity to educate children on the importance of
            dining together as a family, teach them dining etiquette, expose
            them to a variety of cuisine, and inculcate healthy eating habits.
            The school houses a full-fledged modern kitchen where food is
            hygienically prepared as per a menu catered to meet the nutritional
            needs of the students. By providing students with a taste of
            authentic and traditional dishes from different regions of India and
            around the world, the menu takes into consideration dietary
            guidelines and strives to offer appealing and flavorful meals that
            cater to the diverse tastes and preferences of the students. It also
            incorporates different cooking methods like grilling, baking, and
            steaming to promote healthier choices. The students have the
            opportunity to explore different flavors and culinary traditions
            from around the world which not only introduces them to new tastes
            but also promotes cultural awareness and appreciation for different
            cultures and their cuisines. Bonding while eating together in a
            school setting, is a valuable and cherished experience that promotes
            connection, communication, and a sense of community. Students have
            the option to opt for school dining facility or may bring their own
            tiffin.
          </p>
        </div>
      </div>
      {/* <hr className="hr-01" /> */}
    </>
  );
};

export default Infrastructure;
