import React from "react";
import "./Nutrition.css";
import Navbar from "../../components/Navbar";

const BelowSixYears = () => {
  return (
    <>
    
    <Navbar />
    
    <div className="meal-plan-container">
      <h2 className="age-group-title">Nutrition For Below 6 Years</h2>

      <div className="meal-day">
        <h3 className="day-title">Monday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Whole grain cereal with milk, banana slices - <em>Provides essential vitamins and fiber.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Yogurt with berries - <em>Good source of calcium and probiotics.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Lentil soup with whole grain bread, steamed carrots, and peas - <em>Rich in protein and fiber.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Sliced cucumbers and carrots with hummus - <em>Healthy fats and crunchy vegetables.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Paneer curry with chapati, mixed vegetable stir-fry - <em>High in protein and essential nutrients.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk with turmeric - <em>Calming and aids digestion.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Scrambled eggs with whole grain toast, orange slices - <em>Protein-rich start to the day.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Cheese cubes and apple slices - <em>Balanced snack with protein and fiber.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chicken stew with brown rice, steamed broccoli, and carrots - <em>Nutritious and filling meal.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Yogurt with honey and mixed fruits - <em>Calcium and natural sweetness.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Fish curry with rice, green beans, and peas - <em>Lean protein and vegetables.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Tuesday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Oatmeal with fresh fruit and nuts - <em>Rich in fiber and healthy fats.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Apple slices with peanut butter - <em>Balanced snack with protein and vitamins.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Vegetable korma with rice, mixed vegetable salad - <em>Nutritious and flavorful.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Whole grain crackers with cheese - <em>Healthy and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Spinach and cheese stuffed chapati, lentil soup - <em>Rich in iron and protein.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk with a dash of cinnamon - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Greek yogurt with honey and granola - <em>Protein-rich and tasty.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Sliced turkey and cheese roll-ups - <em>High in protein.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Turkey meatballs with spaghetti, green beans - <em>Balanced and satisfying meal.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with spinach, banana, and milk - <em>Nutrient-packed.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Baked salmon with quinoa, roasted vegetables - <em>Rich in omega-3s and fiber.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Wednesday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Smoothie bowl with granola and mixed fruits - <em>Nutrient-packed and delicious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Mixed nuts and dried fruits - <em>Energy-boosting snack.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chickpea curry with brown rice, mixed vegetable salad - <em>Protein and fiber-rich meal.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Fruit yogurt parfait - <em>Calcium and vitamins.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Palak paneer with chapati, cucumber and tomato salad - <em>Iron and calcium-rich.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk with honey - <em>Calming and aids digestion.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Omelette with spinach and cheese, whole grain toast - <em>High in protein and nutrients.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Turkey and cheese roll-ups - <em>Protein-rich snack.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Grilled chicken wrap with vegetables - <em>Lean protein and fiber.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Fruit smoothie with whey protein - <em>High in protein and vitamins.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Beef stew with potatoes and carrots - <em>Hearty and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Thursday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Whole grain pancakes with berries - <em>Delicious and fiber-rich.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Carrot sticks with hummus - <em>Healthy and crunchy.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Quinoa salad with mixed vegetables and feta - <em>Protein and nutrient-packed.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Apple slices with almond butter - <em>Healthy fats and fiber.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Stuffed bell peppers with brown rice - <em>Nutritious and filling.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk with a dash of nutmeg - <em>Calming and aids digestion.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Greek yogurt with granola and honey - <em>Protein-rich and satisfying.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Hard-boiled eggs - <em>High in protein.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chicken and vegetable stir-fry with brown rice - <em>Balanced and nutritious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with banana and spinach - <em>Nutrient-rich and delicious.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Pork tenderloin with roasted sweet potatoes and green beans - <em>Hearty and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Friday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Avocado toast with a side of mixed fruit - <em>Healthy fats and vitamins.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Trail mix with nuts and seeds - <em>Energy-boosting and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Veggie burger with sweet potato fries - <em>Plant-based protein and fiber.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Cottage cheese with pineapple - <em>Protein-rich and refreshing.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Tofu stir-fry with vegetables and rice - <em>Protein and fiber-rich meal.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk with honey - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Bacon and eggs with whole grain toast - <em>Protein-rich and satisfying.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Yogurt with mixed berries - <em>Calcium and antioxidants.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Grilled chicken Caesar salad - <em>Lean protein and fresh vegetables.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with protein powder and fruit - <em>High in protein and vitamins.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Shrimp pasta with vegetables - <em>Delicious and balanced meal.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Saturday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Whole wheat waffles with fruit and yogurt - <em>Healthy and delicious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Mixed fruit salad - <em>Refreshing and full of vitamins.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Vegetable quesadillas with salsa - <em>Flavorful and nutritious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Celery sticks with peanut butter - <em>Healthy and crunchy.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Vegetable curry with basmati rice - <em>Spiced and comforting meal.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk with cardamom - <em>Calming and aids digestion.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Chicken sausage with scrambled eggs - <em>High in protein and satisfying.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Cottage cheese with peaches - <em>Protein-rich and tasty.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Beef tacos with lettuce and tomatoes - <em>Flavorful and filling meal.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with mixed berries - <em>Antioxidants and vitamins.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Baked chicken thighs with roasted vegetables - <em>Hearty and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Sunday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Chia pudding with fruit and nuts - <em>Rich in omega-3s and fiber.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Fruit smoothie - <em>Refreshing and nutrient-packed.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Vegetable pasta with a side salad - <em>Delicious and nutrient-rich.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Yogurt with honey and granola - <em>Calcium and crunch.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Stuffed zucchini with quinoa - <em>Healthy and satisfying.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk with vanilla - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Breakfast burrito with eggs, cheese, and salsa - <em>Protein-rich and satisfying.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Sliced chicken with apple slices - <em>Protein and fiber-rich.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chicken curry with rice - <em>Hearty and flavorful meal.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with spinach and berries - <em>Nutrient-rich.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Grilled fish with sweet potato mash and peas - <em>Delicious and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>
    </div>

    
    </>


  );
};

export default BelowSixYears;
