import React from 'react';
import Navbar from '../components/Navbar';

const FAQ = () => {
  const faqs = [
    {
      question: 'What curriculum does GD Goenka follow?',
      answer: 'GD Goenka follows the CBSE (Central Board of Secondary Education) curriculum, integrated with international educational standards to provide a holistic and globally relevant education.',
    },
    {
      question: 'What are the unique features of GD Goenka?',
      answer: 'GD Goenka offers advanced science labs, a state-of-the-art computer lab, AI and robotics labs, international-standard playgrounds, air-conditioned classrooms, and a focus on holistic education including academics, sports, arts, and life skills.',
    },
    {
      question: 'What age group of students do you admit?',
      answer: 'We admit students from Pre-Nursery to Class XII, ensuring a continuous and comprehensive educational journey from early childhood through higher secondary education.',
    },
    {
      question: 'How can I apply for admission?',
      answer: 'You can apply for admission by visiting our Admissions page on the website, filling out the online form, or visiting our school office in person. Our admissions team will guide you through the process.',
    },
    {
      question: 'What extracurricular activities does the school offer?',
      answer: 'We offer a wide range of extracurricular activities, including sports, arts (music, dance, drama), public speaking, debate, coding, robotics, and various clubs to promote holistic development.',
    },
    {
      question: 'What sports facilities are available at the school?',
      answer: 'Our sports facilities include international-standard playgrounds, courts for basketball, tennis, and badminton, a cricket pitch, a swimming pool, and dedicated areas for athletics and gymnastics.',
    },
    {
      question: 'Does the school provide transport facilities?',
      answer: 'Yes, we provide safe and reliable transport facilities across major areas in and around, with GPS-enabled buses and trained staff to ensure the safety of our students.',
    },
    {
      question: 'How does GD Goenka ensure the safety and security of students?',
      answer: 'We have 24/7 security personnel, CCTV surveillance across the campus, a secure entry and exit system, and regular safety drills. Our staff is trained in child protection and emergency response.',
    },
    {
      question: 'What is the student-teacher ratio?',
      answer: 'We maintain an optimal student-teacher ratio to ensure personalized attention and effective learning for every child, typically around 20:1.',
    },
    {
      question: 'Are there facilities for special education needs?',
      answer: 'Yes, we are committed to inclusive education and have a dedicated support team, including special educators and counselors, to cater to students with diverse learning needs.',
    },
    {
      question: 'What is the medium of instruction?',
      answer: 'The medium of instruction at GD Goenka is English. However, we also emphasize the importance of regional languages and provide additional language learning opportunities.',
    },
    {
      question: 'What digital resources are available for students?',
      answer: 'Our computer lab is equipped with advanced software, high-speed internet, and digital tools. We provide training in digital literacy, coding, and robotics, along with access to a wide range of online learning platforms.',
    },
    {
      question: 'How does the school handle student discipline?',
      answer: 'We have a clear code of conduct and a positive discipline policy in place. We focus on building character and values through positive reinforcement, counseling, and guidance.',
    },
    {
      question: 'Does the school offer health and medical facilities?',
      answer: 'Yes, we have an on-campus medical room staffed with a qualified nurse and a tie-up with Kaminene Hospital for regular health check-ups and emergency medical care.',
    },
    {
      question: "How can parents stay informed about their child's progress?",
      answer: 'We have an open communication policy with regular parent-teacher meetings, progress reports, and updates through our school portal and mobile app. Parents can also schedule meetings with teachers as needed.',
    },
  ];

  return (
    <>

    <Navbar />
    
    <div className="section-mainbox">
      <h2>Frequently Asked Questions (FAQs)</h2>
      <ul className="faq-list">
        {faqs.map((faq, index) => (
          <li key={index} className="faq-item">
            <h3 className="faq-question">{faq.question}</h3>
            <p className="faq-answer">{faq.answer}</p>
          </li>
        ))}
      </ul>
    </div>
    
    </>
  );
};

export default FAQ;
