import React from 'react';
import Navbar from '../components/Navbar';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useMediaQuery } from '@mui/material';

const GdGoenkaHouses = () => {

  const itemData = [
    { img: './images/house-001.jpg', title: 'House 001' },
    { img: './images/house-002.jpg', title: 'House 002' },
    // { img: './images/house-003.jpg', title: 'House 003' },
    // { img: './images/house-004.jpg', title: 'House 004' },
    // { img: './images/house-005.jpg', title: 'House 005' },
    // { img: './images/house-006.jpg', title: 'House 006' },
    // { img: './images/house-007.jpg', title: 'House 007' },
    // { img: './images/house-008.jpg', title: 'House 008' },
    // { img: './images/house-009.jpg', title: 'House 009' },
    // { img: './images/house-010.jpg', title: 'House 010' },
    // { img: './images/house-011.jpg', title: 'House 011' },
    // { img: './images/house-012.jpg', title: 'House 012' },
    { img: './images/house-013.jpg', title: 'House 013' },
    { img: './images/house-014.jpg', title: 'House 014' },
    { img: './images/house-015.jpg', title: 'House 015' },
  ];

  // Use media queries to set the number of columns dynamically
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const isMediumScreen = useMediaQuery('(max-width: 960px)');
  const isLargeScreen = useMediaQuery('(min-width: 961px)');

  let cols = 4; // Default for large screens
  if (isMediumScreen) {
    cols = 2; // Adjust to 2 columns on medium screens
  }
  if (isSmallScreen) {
    cols = 1; // Adjust to 1 column on small screens
  }

  return (
    <>
      <Navbar />

      <h1 className="box-title2">Gd Goenka Houses</h1>

      <div className="houses-container">
        <div className="house-box">
          <img src="./images/house-1.jpg" alt="Aryabhatta House" className="house-img" />
          <h2 className="house-heading">ARYABHATTA HOUSE</h2>
        </div>
        <div className="house-box">
          <img src="./images/house-2.jpg" alt="Chanakya House" className="house-img" />
          <h2 className="house-heading">CHANAKYA HOUSE</h2>
        </div>
        <div className="house-box">
          <img src="./images/house-3.jpg" alt="CV Raman House" className="house-img" />
          <h2 className="house-heading">CV RAMAN HOUSE</h2>
        </div>
        <div className="house-box">
          <img src="./images/house-4.jpg" alt="Sushrutha House" className="house-img" />
          <h2 className="house-heading">SUSHRUTHA HOUSE</h2>
        </div>
      </div>

      {/* Responsive ImageList */}
      <ImageList
        sx={{ width: '100%', maxWidth: 1200, margin: '0 auto' }}
        variant="quilted"
        cols={cols}
        rowHeight={121}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=121&h=121&fit=crop&auto=format`}
              srcSet={`${item.img}?w=121&h=121&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}

export default GdGoenkaHouses;
