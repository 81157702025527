import React from "react";
import "./ImageScroll.css";

const ImageScroll = () => {
  const images = [
    "./images/img-1.png",
    "./images/img-2.png",
    "./images/img-3.png",
    "./images/img-4.png",
    "./images/img-5.png",
    "./images/img-6.png",
  ];

  return (
    <div className="scroll-container">
      <div className="scroll-content">
        {images.map((image, index) => (
          <div key={index} className="scroll-image">
            <img src={image} alt={`img-${index}`} />
          </div>
        ))}
        {images.map((image, index) => (
          <div key={index + images.length} className="scroll-image">
            <img src={image} alt={`img-${index + images.length}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageScroll;
